import React, { useRef, useContext, useEffect } from "react";
import posed from "react-pose";
import { styled, media, white } from "../../styles";
import { SidebarContext } from "./SidebarContext";
import { Link } from "../Link";
import { List, ListItem } from "../List";
import lightningImg from "./assets/lightning.svg";
import sidebarBgImg from "./assets/sidebar_bg.svg";

const links: Array<{ name: string; href: string }> = [
  {
    name: "Work",
    href: "/work/"
  },
  {
    name: "Studio",
    href: "/studio/"
  },
  {
    name: "Music",
    href: "/music/"
  },
  {
    name: "Specials",
    href: "/specials/"
  },
  {
    name: "Team",
    href: "/team/"
  },
  {
    name: "About",
    href: "/#about"
  },
  {
    name: "Awards",
    href: "/awards/"
  },
  {
    name: "Presse & News",
    href: "/presse-und-news/"
  }
];

export const Sidebar: React.FC<{}> = () => {
  const sidebarRef = useRef(null);
  const [isSidebarVisible, toggleSidebar] = useContext(SidebarContext);

  const onClick = (event: any) => {
    if (
      (event.target && event.target.id && event.target.id === "burger_close") ||
      (event.target &&
        sidebarRef &&
        sidebarRef.current &&
        Array.isArray(sidebarRef.current) &&
        // @ts-ignore
        sidebarRef.current.contains(event.target))
    ) {
      toggleSidebar();
    }
  };

  useEffect(() => {
    if (isSidebarVisible) {
      window.addEventListener("click", onClick, { passive: true });

      return () => {
        window.removeEventListener("click", onClick);
      };
    }
  }, [onClick, isSidebarVisible]);

  return (
    <SidebarContainer
      initialPose="closed"
      pose={isSidebarVisible ? "open" : "closed"}
      ref={sidebarRef}
    >
      <SidebarContent>
        <SidebarList>
          {links.map(({ name, href }) => (
            <SidebarListItem key={name}>
              <Link to={href}>{name}</Link>
            </SidebarListItem>
          ))}
        </SidebarList>

        <SidebarAddress>
          <Link to="/">
            <img
              alt="Lightning"
              src={lightningImg}
              width="48px"
              height="42px"
            />
          </Link>
          <br />
          <a
            target="_NEW"
            href="https://www.google.de/maps/place/German+Wahnsinn+GmbH/@53.5485568,9.9636215,17z/data=!3m1!4b1!4m5!3m4!1s0x47b18f728dcbe6f7:0x410150cab91470e7!8m2!3d53.5485568!4d9.9658102"
          >
            German Wahnsinn GmbH
            <br />
            Kastanienallee 9, 20359 Hamburg
          </a>
          <br />
          <a href="mailto:wahnsinn@germanwahnsinn.de">
            wahnsinn@germanwahnsinn.de
          </a>
          <br />
          <span>
            <a href="tel:+494032536244">+49 40 - 32 53 62 44</a>
          </span>
        </SidebarAddress>
      </SidebarContent>
    </SidebarContainer>
  );
};

const PosedSidebar = posed.aside({
  open: {
    x: "0%",
    transition: {
      ease: "easeOut",
      duration: 450
    },
    staggerChildren: 30
  },
  closed: {
    x: "-100%",
    transition: {
      ease: "easeInOut",
      duration: 250
    }
  }
});

const SidebarContainer = styled(PosedSidebar)`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 200;

  display: flex;
  flex-direction: column;
  background-image: url(${sidebarBgImg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: ${white};

  width: 30rem;
  max-width: 100%;
  min-height: 100vh;

  transform: translateX(-100%) translateZ(0px);

  ${media.tablet} {
    width: 30rem;
  }

  ${media.landscape} {
    width: 100%;
  }
`;

const SidebarContent = styled.nav`
  margin: 3rem 30% 0 auto;
  text-align: right;
  transform: rotate(3.5deg);

  ${media.tablet} {
    margin: 3rem 9rem 0 auto;
  }

  ${media.landscape} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 1rem 32% 0 15%;
  }
`;

const SidebarAddress = styled.div`
  margin-top: 2rem;

  ${media.landscape} {
    margin-top: 1.5rem;
  }

  ${media.tablet} {
    margin-top: 5rem;
  }

  img,
  span,
  a {
    display: inline-block;
    margin: 0.3rem 0;
    font-weight: 400;
    font-size: 1rem;
    font-family: "NittiGrotesk";
    letter-spacing: 1.2;

    &:hover {
      color: ${white};
    }
  }

  text-align: right;
`;

const SidebarList = styled(List)``;

/*
const PosedListItem = posed(ListItem)({
  open: {
    opacity: 1,
    transition: {
      duration: 450
    }
  },
  closed: { opacity: 0 }
});
*/

const SidebarListItem = styled(ListItem)`
  margin: 1rem 0;

  ${Link} {
    font-size: 2rem;
    font-weight: 700;
    font-family: "NittiGrotesk-Bold";
    letter-spacing: 1.1;

    &:hover {
      color: ${white};
    }
  }
`;
